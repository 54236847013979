import { Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Loading from '@/components/elements/Loading';
import NotFound from '@/components/pages/NotFound';
import useSafeParams from '@/hooks/useSafeParams';
import DeleteMutationButton from '@/modules/dataFetching/components/DeleteMutationButton';
import EditRecord from '@/modules/form/components/EditRecord';
import { useProjectDashboardContext } from '@/modules/projects/components/ProjectDashboard';
import { ProjectFormTitle } from '@/modules/projects/components/ProjectOverviewPage';
import { cache } from '@/providers/apolloClient';
import { ProjectDashboardRoutes } from '@/routes/routes';
import {
  DeleteProjectCocDocument,
  DeleteProjectCocMutation,
  DeleteProjectCocMutationVariables,
  ProjectCocFieldsFragment,
  RecordFormRole,
  useGetProjectCocQuery,
} from '@/types/gqlTypes';
import { generateSafePath } from '@/utils/pathEncoding';

const EditProjectCocPage = ({ create = false }: { create?: boolean }) => {
  const navigate = useNavigate();
  const { projectId, cocId } = useSafeParams() as {
    projectId: string;
    cocId: string;
  };
  const title = create ? `Add Project CoC` : `Edit Project CoC`;
  const { project } = useProjectDashboardContext();

  const {
    data: { projectCoc } = {},
    loading,
    error,
  } = useGetProjectCocQuery({
    variables: { id: cocId },
    skip: create,
  });

  const onCompleted = useCallback(() => {
    // Force refresh table if we just created a new record
    if (create) {
      cache.evict({ id: `Project:${projectId}`, fieldName: 'projectCocs' });
    }
    navigate(generateSafePath(ProjectDashboardRoutes.COCS, { projectId }));
  }, [navigate, projectId, create]);

  const onSuccessfulDelete = useCallback(() => {
    // Force re-fetch table after deletion
    cache.evict({ id: `Project:${projectId}`, fieldName: 'projectCocs' });
    navigate(generateSafePath(ProjectDashboardRoutes.COCS, { projectId }));
  }, [projectId, navigate]);

  const titleComponent = useMemo(() => {
    if (!create && projectCoc) {
      return (
        <ProjectFormTitle
          title={title}
          project={project}
          actions={
            <DeleteMutationButton<
              DeleteProjectCocMutation,
              DeleteProjectCocMutationVariables
            >
              queryDocument={DeleteProjectCocDocument}
              variables={{ input: { id: projectCoc.id } }}
              idPath={'deleteProjectCoc.projectCoc.id'}
              recordName='Project CoC record'
              ConfirmationDialogProps={{ confirmText: 'Yes, delete' }}
              onSuccess={onSuccessfulDelete}
            >
              Delete Record
            </DeleteMutationButton>
          }
        />
      );
    }
    return (
      <Typography component='h1' variant='h3'>
        {title}
      </Typography>
    );
  }, [create, onSuccessfulDelete, project, projectCoc, title]);

  if (!project.access.canEditProjectDetails) return <NotFound />;
  if (loading) return <Loading />;
  if (error) throw error;

  return (
    <>
      <EditRecord<ProjectCocFieldsFragment>
        FormActionProps={
          create ? { submitButtonText: 'Create Project CoC' } : undefined
        }
        onCompleted={onCompleted}
        formRole={RecordFormRole.ProjectCoc}
        inputVariables={{ projectId }}
        record={projectCoc || undefined}
        title={titleComponent}
      />
    </>
  );
};
export default EditProjectCocPage;
